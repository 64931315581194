<template>
	<div>
		<section class="card shadow rythm-v-large"
						 v-if="caseData?.sessions">

			<div class="input-group--navigation flex-item--100 flex-row flex-center flex-gap">
				<router-link v-for="session in caseData.sessions"
										 :key="`link_${session.week}`"
										 :class="sessionNavItemClasses(session)"
										 :to="`/cases/${caseID}/sessions/${session.id}`"
										 class="button--secondary">
					<span v-if="parseInt(session.week) === 1">Introduction – S1</span>
					<span v-else>S{{ session.week }}</span>
				</router-link>

				<router-link :to="`/cases/${caseID}/adverse-events`"
										 class="button--secondary">
					<span>Évènements indésirables</span>
				</router-link>

				<router-link :to="`/cases/${caseID}/study-end`"
										 class="button--secondary">
					<span>Fin de l’étude</span>
				</router-link>
			</div>


			<h2 v-html="currentSession.title"
					class="current-session-title"></h2>

			<form ref="saveForm"
						id="saveForm"
						method="post"
						@submit.prevent="saveCase"></form>

			<ClinicalData form="saveForm"
										:key="`clinical_data_${caseID}_s${sid}`"
										:session="currentSession"
										:previousSession="previousSession"
										:disease_type="caseData.desease_data.disease_type"
										v-model="caseData.sessions[sid].clinical_data" />


			<!-- Biological data -->
			<section class="section rythm-v-large">
				<h3 id="biological-data">Données biologiques</h3>

				<div class="flex-row flex-gap-large flex-center-v">

					<div class="flex-item">
						<radio-toggle class="radio-group--inline"
													form="saveForm"
													id="has_biological_data"
													:label="`Avez-vous des données biologiques ?`"
													v-model="caseData.sessions[sid].biological_data.has_biological_data"
													@input="toggleBiologicalData"
													required></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="parseInt(caseData.sessions[sid].biological_data.has_biological_data)">
							<label for="date">Date</label>
							<input :id="`s${sid}_biological_data_date`"
										 :name="`s${sid}_biological_data_date`"
										 form="saveForm"
										 type="date"
										 v-model="caseData.sessions[sid].biological_data.date"
										 required />
						</div>
					</div>
				</div>

				<BiologicalData form="saveForm"
												:key="`clinical_data_${caseID}_s${sid}`"
												v-if="parseInt(caseData.sessions[sid].biological_data.has_biological_data)"
												v-model="caseData.sessions[sid].biological_data" />

			</section>


			<!-- ASDAS -->
			<section class="rythm-v-large"
							 v-if="caseData.desease_data.disease_type === 'Spondylarthrite'">

				<!-- Score ASDAS – using values from BASDAI and EVA -->
				<!-- NOTE: getting values from caseData.sessions[sid].clinical_data (EVA, BASDAI, …) -->
				<ScoreAsdas form="saveForm"
										v-model="caseData.sessions[sid].score_asdas"
										:activity_patient="caseData.sessions[sid].clinical_data.eva.activity_patient"
										:pain="caseData.sessions[sid].clinical_data?.score_basdai?.values?.pain"
										:stiffness_duration="caseData.sessions[sid].clinical_data?.score_basdai?.values?.stiffness_duration"
										:swelling="caseData.sessions[sid].clinical_data?.score_basdai?.values?.swelling"
										:crp="caseData.sessions[sid].biological_data?.list?.crp?.value"
										:vs="caseData.sessions[sid].biological_data?.list?.vs?.value" />

			</section>


			<!-- Echo data – Inclusion, Semaine 4, Semaine 8,	Semaine 12 -->
			<section class="section rythm-v-large"
							 v-if="[1, 4, 8, 12].includes(parseInt(currentSession.week))">
				<h3>Données échographiques</h3>

				<div class="flex-row flex-gap-large flex-center-v">

					<div class="flex-item">
						<radio-toggle class="radio-group--inline required"
													form="saveForm"
													:id="`s${sid}_has_echo_data`"
													:name="`s${sid}_has_echo_data`"
													:label="`Échographie réalisée ?`"
													v-model="caseData.sessions[sid].echo_data.has_echo_data"
													@input="toggleEchoData"
													required></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="parseInt(caseData?.sessions[sid]?.echo_data?.has_echo_data)">
							<label :for="`s${sid}_echo_data_date`">Date</label>
							<input :id="`s${sid}_echo_data_date`"
										 :name="`s${sid}_echo_data_date`"
										 form="saveForm"
										 type="date"
										 v-model="caseData.sessions[sid].echo_data.date"
										 required />
						</div>
					</div>
				</div>

				<EchoData form="saveForm"
									v-if="parseInt(caseData.sessions[sid].echo_data.has_echo_data)"
									v-model="caseData.sessions[sid].echo_data" />

			</section>


			<!-- Controls -->
			<div class="flex-row flex-gap-large">

				<!-- Save button -->
				<div class="input-group--controls flex-item--100 flex-row flex-center-h rythm-h flex-gap">
					<button type="submit"
									form="saveForm"
									name="saveButton"
									class="button--cta"
									ref="saveButton"
									v-if="sid !== ''">
						<IconSave class="icon" />
						<span class="caption">Enregistrer</span>
					</button>
				</div>

				<!-- Navigation -->
				<div class="input-group--controls input-group--navigation flex-item--100 flex-row flex-center flex-gap">
					<router-link :to="previousPage"
											 class="button--secondary">
						<IconLeft />
						<span class="caption">Précédent</span>
					</router-link>

					<router-link v-for="session in caseData.sessions"
											 :key="`link_${session.week}`"
											 :class="sessionNavItemClasses(session)"
											 :to="`/cases/${caseID}/sessions/${session.id}`"
											 class="button--secondary">
						<span>S{{ session.week }}</span>
					</router-link>

					<router-link :to="`/cases/${caseID}/adverse-events`"
											 class="button--secondary">
						<span>Évènements indésirables</span>
					</router-link>

					<router-link :to="`/cases/${caseID}/study-end`"
											 class="button--secondary">
						<span>Fin de l’étude</span>
					</router-link>

					<button type="submit"
									form="saveForm"
									class="button--primary"
									v-if="sid !== ''">
						<span class="caption">Continuer</span>
						<IconRight />
					</button>

				</div>
			</div>

		</section>
	</div>
</template>

<script>
import IconSave from '../assets/svg/icons/icon-save.svg';
import IconRight from '../assets/svg/icons/icon-right.svg';
import IconLeft from '../assets/svg/icons/icon-left.svg';
import radioToggle from "@/components/radioToggle.vue";

import ScoreAsdas from "@/components/ScoreAsdas.vue";
import ClinicalData from "@/components/ClinicalData.vue";
import BiologicalData from "@/components/BiologicalData.vue";
import EchoData from "@/components/EchoData.vue";

export default {
	name: "DashboardCaseSessions",
	props: ["caseID"],
	components: { radioToggle, IconSave, IconRight, IconLeft, ScoreAsdas, ClinicalData, BiologicalData, EchoData },

	data: function () {
		return {
			sid: "s1",
			previousSession: null,
			nextSession: null,
			previousPage: `/cases/${this.caseID}/treatments`,
			nextPage: `/cases/${this.caseID}/sessions/s2`,
			caseData: {},
		};
	},

	computed: {
		currentSession() {
			if (!this?.sid || !this?.caseData?.sessions) return null;
			return this.caseData.sessions[this.sid];
		},
	},

	methods: {

		sessionNavItemClasses(session) {
			let classes = [];

			if (this.sid === session.id) {
				classes.push("is-current");
			}

			if (session.form_completed) {
				classes.push("is-completed");
			}

			return classes.join(" ");
		},

		// reset echo data if !has_echo_data
		toggleEchoData() {
			if (!parseInt(this.caseData.sessions[this.sid].echo_data.has_echo_data)) {
				this.caseData.sessions[this.sid].echo_data.date = "";

				// reset echo data for each type's localisation articluations
				Object.values(this.caseData.sessions[this.sid].echo_data.list).forEach((type) => {
					// type: synovites / tenosynovites
					Object.values(type).forEach((localization) => {
						// localization (left_hand, right_hand)
						if (!localization?.data) return;

						// articulations (mcp1, mcp2, etc)
						Object.values(localization.data).forEach((item) => {
							item.mode_b = "";
							item.doppler = "";
						});
					})
				})

			}
		},

		toggleBiologicalData() {
			if (!parseInt(this.caseData.sessions[this.sid].biological_data.has_biological_data)) {
				console.log("toggleBiologicalData", this.sid);

				this.caseData.sessions[this.sid].biological_data.date = "";

				Object.values(this.caseData.sessions[this.sid].biological_data.list).forEach((item) => {
					item.selected = "";
					item.value = "";
				})
			}
		},

		// Save data on session change
		submitForm() {
			// report form errors
			this.$refs.saveForm.reportValidity();

			// check if form is valid and submit
			if (this.formValidates(this.$refs.saveForm)) {
				const saveButton = this.$refs.saveButton;
				saveButton.click();
			}
		},

		/**
		 * Check if all form elements pass html5 form validation
		 */
		formValidates(form) {
			console.log("form", form);
			let inputs = form.elements;
			if (!inputs) return undefined;
			let validations = [];
			[...inputs].map((input) => {
				validations.push(input.checkValidity());
			});
			let validates = validations.every((v) => v === true);
			return validates;
		},

		// Save the case
		saveCase(event) {
			const submitter = event?.submitter?.name;
			const validates = this.$refs.saveForm.checkValidity();
			if (!validates) return; ""

			// Set session as completed if the form submits
			if (this.sid !== "") {
				this.caseData.sessions[this.sid].form_completed = true;
			}

			this.$store
				.dispatch("SAVE_CASE", this.caseData)
				.then((caseData) => {
					this.$toasted.clear();

					// Reload all data
					this.caseData = caseData;
					const message = "Informations enregistrées";
					const toast = this.$toasted.global.appSuccess({
						message: message,
					});

					setTimeout(function () {
						toast.goAway();
					}, 5000);

					// save only
					if (submitter === "saveButton") return;

					// continue to next session / page
					this.scrollToTop();
					this.$router.push(this.nextPage);
				})
				.catch((error) => {
					console.warn(error);
					return [];
				});
		},

		// Load case data from database
		async loadCase() {
			let caseData = await this.$store.state.caseData;

			if (null === caseData) {
				this.$router.push(`/cases`);
				return;
			}

			// Set caseData
			this.caseData = { ...caseData };
		},

		updateCurrentSessionSiblings() {

			if (this.currentSession?.id) {
				// clone this.caseData.sessions to not modify the original
				const _sessions = { ...this.caseData.sessions };

				// make an array of sessions
				const sessionsArray = Object.values(_sessions);

				// find the previous session (where the week prop is inferior to the current session week)
				sessionsArray.sort((a, b) => parseInt(a.week) < parseInt(b.week)); // order sessions by week DESC
				const previousSession = sessionsArray.find(session => parseInt(session.week) < parseInt(this.currentSession.week));
				const previousSessionID = previousSession?.id ? previousSession.id : false;
				this.previousSession = previousSessionID ? this.caseData.sessions[previousSessionID] : null;
				this.previousPage = this.previousSession ? `/cases/${this.caseID}/sessions/${this.previousSession.id}` : `/cases/${this.caseID}/treatments`;

				// find the next session (where the week prop is superior to the current session week)
				if (this.currentSession.id === "s48") {
					this.nextSession = null;
					this.nextPage = `/cases/${this.caseID}/study-end`;
				}
				else {
					sessionsArray.sort((a, b) => parseInt(a.week) > parseInt(b.week)); // order sessions by week ASC
					const nextSession = sessionsArray.find(session => parseInt(session.week) > parseInt(this.currentSession.week));
					const nextSessionID = nextSession?.id ? nextSession.id : false;
					this.nextSession = nextSessionID ? this.caseData.sessions[nextSessionID] : this.caseData.sessions["s48"];
					this.nextPage = `/cases/${this.caseID}/sessions/${this.nextSession.id}`;
				}
			}
			else {
				this.previousPage = `/cases/${this.caseID}/treatments`;
				this.nextPage = `/cases/${this.caseID}/sessions/s1`;
			}

		}
	},

	async mounted() {
		await this.loadCase();
		this.updateCurrentSessionSiblings();
	},


	watch: {
		// When the route session ID changes
		"$route.params.sid": {
			immediate: true,
			handler: function (sid) {
				// current session ID
				if (!sid || typeof sid === "undefined") {
					this.sid = "s1";
				}
				this.sid = sid;

				// session navigation
				this.updateCurrentSessionSiblings();
			}
		}
	},

};
</script>


<style lang="scss">
@import "~@/scss/common-views.scss";
@import "~@/scss/99-utilities/albatross.scss";
@import "~@/scss/06-components/_components.tag.scss";
@import "~@/scss/06-components/_components.schema-gma.scss";

.fake-input {
	border: none;
}
</style>

<style lang="scss" scoped>
.flex-row h3,
.flex-row h4 {
	flex-basis: 100%;
}

.section--session-data>section {
	flex-basis: 100%;
}

.section--sessions-list {
	margin-bottom: 2rem;

	.label {
		margin-bottom: 1rem;
	}

	input:checked+.caption {
		font-weight: bold;
	}

	.is-incomplete label::after {
		content: "(à compléter)";
		color: red;
		font-style: italic;
		display: inline;
	}

	.sessions-list {
		display: inline-block;

		.is-current {
			list-style-type: disclosure-closed;
		}
	}
}


.input-group--navigation {
	a.is-completed {
		position: relative;

		&::after {
			content: "";
			height: 3px;
			position: absolute;
			bottom: -1px;
			left: 0;
			right: 0;
			background: rgb(0, 180, 132);
			border-radius: inherit;
		}
	}
}

@media (max-width: 768px) {
	.input-group--navigation {
		justify-content: space-evenly;

		button:last-child,
		a:first-child {
			flex-basis: 100%;
			justify-content: center;
		}
	}
}
</style>
