<template>
	<section class="section--session-data rythm-v-large"
					 v-if="value">

		<h3>Données cliniques</h3>

		<section class="flex-row flex-gap">

			<div class="flex-item--grow">
				<div class="input-group required">
					<label for="date"
								 v-if="session.id === 's1'">Date de l’introduction</label>
					<label for="date"
								 v-else>Date de la visite</label>

					<input id="date"
								 name="date"
								 type="date"
								 v-model="value.date"
								 v-bind="$attrs"
								 required />
				</div>
			</div>

			<div class="flex-item--grow"
					 v-if="parseInt(session.week) < 2">
				<div class="input-group required">
					<label for="patient_size">Taille <small>(cm)</small></label>
					<input id="patient_size"
								 name="patient_size"
								 type="number"
								 v-model="value.size"
								 v-bind="$attrs"
								 required />
				</div>
			</div>

			<div class="flex-item--grow">
				<div class="input-group required">
					<label for="patient_weight">Poids <small>(kg)</small></label>
					<input id="patient_weight"
								 name="patient_weight"
								 type="number"
								 v-model="value.weight"
								 v-bind="$attrs"
								 required />
				</div>
			</div>

		</section>

		<!-- Reprise / poursuite ADACOLUMN -->
		<section class="flex-col flex-gap"
						 v-if="parseInt(session.week) > 1">

			<div class="flex-item--grow flex-col flex-gap">

				<div class="flex-item">
					<radio-toggle id="maintain_continue"
												label="Reprise / Poursuite Adacolumn®"
												class="radio-group--inline"
												v-model="value.adacolumn.maintain_continue"
												v-bind="$attrs"
												required></radio-toggle>
				</div>

				<div class="flex-row flex-gap">
					<div class="flex-item">
						<radio-toggle id="gma_failure"
													label="Arrêt GMA pour échec"
													class="radio-group--inline"
													v-model="value.adacolumn.gma_failure"
													v-bind="$attrs"
													required
													@input="resetConditionalProps(value.adacolumn, ['gma_failure_date'])"></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="value.adacolumn.gma_failure === '1'">
							<label for="gma_failure_date">Date de l'arrêt GMA :</label>
							<input id="gma_failure_date"
										 name="gma_failure_date"
										 type="date"
										 v-model="value.adacolumn.gma_failure_date"
										 v-bind="$attrs"
										 required />
						</div>
					</div>
				</div>

			</div>

			<!-- S8+ : tous les autres champs sauf chirurgie (rémission, réponse, hospitalisation si pas déjà) -->
			<div class="flex-item--grow flex-col flex-gap"
					 v-if="parseInt(session.week) > 8">

				<div class="flex-item">
					<radio-toggle id="has_additional_sessions"
												label="Réalisation de séances additionnelles"
												class="radio-group--inline"
												v-model="value.adacolumn.has_additional_sessions"
												v-bind="$attrs"
												required
												@input="resetConditionalProps(value.adacolumn, ['additional_sessions_number', 'additional_sessions_rythm'])"></radio-toggle>
				</div>

				<div class="flex-row flex-gap"
						 v-if="value.adacolumn.has_additional_sessions === '1'">

					<div class="flex-item">
						<div class="input-group--inline required">
							<label for="additional_sessions_number">Nombre de séances additionnelles :</label>
							<input id="additional_sessions_number"
										 name="additional_sessions_number"
										 type="number"
										 v-model="value.adacolumn.additional_sessions_number"
										 v-bind="$attrs"
										 required />
						</div>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required">
							<label for="additional_sessions_rythm">Rythme des séances additionnelles :</label>
							<input id="additional_sessions_rythm"
										 name="additional_sessions_rythm"
										 type="text"
										 v-model="value.adacolumn.additional_sessions_rythm"
										 v-bind="$attrs"
										 required />
						</div>
					</div>
				</div>


				<div class="flex-row flex-gap">
					<div class="flex-item">
						<radio-toggle id="remission"
													label="Rémission"
													class="radio-group--inline"
													v-model="value.adacolumn.remission"
													v-bind="$attrs"
													required
													@input="resetConditionalProps(value.adacolumn, ['remission_date'])"></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="value.adacolumn.remission === '1'">
							<label for="remission_date">Date de la rémission :</label>
							<input id="remission_date"
										 name="remission_date"
										 type="date"
										 v-model="value.adacolumn.remission_date"
										 v-bind="$attrs"
										 required />
						</div>
					</div>

				</div>


				<div class="flex-row flex-gap">
					<div class="flex-item">
						<radio-toggle id="response"
													label="Réponse"
													class="radio-group--inline"
													v-model="value.adacolumn.response"
													v-bind="$attrs"
													required
													@input="resetConditionalProps(value.adacolumn, ['response_date'])"></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="value.adacolumn.response === '1'">
							<label for="response_date">Date de la réponse :</label>
							<input id="response_date"
										 name="response_date"
										 type="date"
										 v-model="value.adacolumn.response_date"
										 v-bind="$attrs"
										 required />
						</div>
					</div>
				</div>

				<div class="flex-row flex-gap">
					<div class="flex-item">
						<radio-toggle id="hospitalization"
													label="Hospitalisation"
													class="radio-group--inline"
													v-model="value.adacolumn.hospitalization"
													v-bind="$attrs"
													required
													@input="resetConditionalProps(value.adacolumn, ['hospitalization_date'])"></radio-toggle>
					</div>

					<div class="flex-item">
						<div class="input-group--inline required"
								 v-if="value.adacolumn.hospitalization === '1'">
							<label for="hospitalization_date">Date de l'hospitalisation :</label>
							<input id="hospitalization_date"
										 name="hospitalization_date"
										 type="date"
										 v-model="value.adacolumn.hospitalization_date"
										 v-bind="$attrs"
										 required />
						</div>
					</div>
				</div>

			</div>

		</section>

		<!-- Traitements de fond associés -->
		<section class="rythm-v">
			<hgroup class="rythm-v">
				<h4>Traitements de fond associés</h4>
				<p v-if="parseInt(session.week) > 1">
					<button type="button"
									class="button--secondary"
									@click="loadPreviousSessionTreatmentsData(['associated_background_treatments'])">
						<IconImport></IconImport>
						<span class="caption">Reprendre les données de la session précédente</span>
					</button>
				</p>
			</hgroup>

			<div class="stripped-children">

				<div v-for="(treatment, index) in value.treatments.associated_background_treatments"
						 :key="`clinical_treatment_${index}`"
						 class="flex-row flex-gap">

					<!-- Treatment selected -->
					<div class="flex-item--auto">
						<radio-toggle class="radio-group--inline"
													:id="`${treatment.slug}_selected`"
													:label="treatment.label"
													v-model="treatment.selected"
													v-bind="$attrs"
													required
													@input="resetConditionalProps(treatment, ['name', 'dosage'])"></radio-toggle>
					</div>

					<div class="flex-item--auto">
						<div class="flex-row flex-gap flex-align-right">

							<!-- Treatment name -->
							<div class="flex-item--50"
									 v-if="parseInt(treatment.selected)">
								<div class="input-group--inline required">
									<label :for="`${treatment.slug}_name`">Nom :</label>
									<input type="text"
												 :name="`${treatment.slug}_name`"
												 :id="`${treatment.slug}_name`"
												 v-model="treatment.name"
												 v-bind="$attrs"
												 required />
								</div>
							</div>

							<!-- Treatment dosage -->
							<div class="flex-item--50"
									 v-if="parseInt(treatment.selected)">
								<div class="input-group--inline">
									<label :for="`${treatment.slug}_dosage`">Dosage <small>(mg)</small> :</label>
									<input type="number"
												 step=".01"
												 :name="`${treatment.slug}_dosage`"
												 :id="`${treatment.slug}_dosage`"
												 v-model="treatment.dosage"
												 v-bind="$attrs" />
								</div>
							</div>

							<!-- Sub fields -->
							<template v-if="parseInt(treatment.selected) && treatment?.subfields?.length">
								<div class="flex-item"
										 v-for="subfield in treatment.subfields"
										 :key="subfield.slug">

									<div class="input-group--inline">
										<label :for="subfield.slug"
													 class="text-nowrap">{{ subfield.label }} :</label>
										<input type="text"
													 :name="subfield.slug"
													 :id="subfield.slug"
													 v-model="subfield.value"
													 v-bind="$attrs" />
									</div>
								</div>
							</template>

						</div>
					</div>

				</div>
			</div>

		</section>

		<!-- Autres traitements -->
		<section class="rythm-v">
			<hgroup class="rythm-v">
				<h4>Autres traitements</h4>
				<p v-if="parseInt(session.week) > 1"><button type="button"
									class="button--secondary"
									@click="loadPreviousSessionTreatmentsData(['additional'])">
						<IconImport></IconImport>
						<span class="caption">Reprendre les données de la session précédente</span>
					</button></p>
			</hgroup>

			<table v-if="Object.values(value?.treatments?.additional).length">
				<thead>
					<tr>
						<th>Nom du médicament</th>
						<th>Dosage</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					<tr v-for="(addt, index) in value.treatments.additional"
							:key="`addt_conventional_${index}`">
						<td>{{ addt.label }}</td>
						<td>{{ addt.dosage }}</td>
						<td class="text-right">
							<button type="button"
											class="button--danger--outline"
											title="Supprimer"
											@click="removeTreatment(addt)">
								<IconBin class="icon" />
							</button>
						</td>

					</tr>
				</tbody>
			</table>

			<div class="card rythm-v">

				<!-- Add Treatment -->
				<div class="flex-row flex-gap flex-align-bottom">

					<div class="flex-item--grow">
						<div class="input-group">
							<label for="">Nom</label>
							<input type="text"
										 name="addt_conventional_before_label"
										 id="addt_conventional_before_label"
										 v-model="additionnalTreatment.label" />
						</div>
					</div>

					<div class="flex-item--grow">
						<div class="input-group">
							<label for="addt_conventional_before_dosage">Dosage</label>
							<input type="text"
										 name="addt_conventional_before_dosage"
										 id="addt_conventional_before_dosage"
										 v-model="additionnalTreatment.dosage" />
						</div>
					</div>

					<div class="flex-item">
						<div class="input-group--controls">
							<button class="button--secondary"
											type="button"
											@click="addTreatment()"
											:disabled="!additionnalTreatment.label">
								<IconPlus class="icon" />
								<span class="caption">Ajouter</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Évènements indésirables -->
		<section class="rythm-v">
			<h4>Évènements indésirables</h4>

			<div class="rythm-v">

				<radio-toggle v-if="parseInt(session.week) > 1"
											class="radio-group--inline"
											id="has_adverse_events_last_visit"
											label="Évènements indésirables depuis la dernière visite ?"
											v-model="value.treatments.has_adverse_events_last_visit"
											v-bind="$attrs"
											required></radio-toggle>

				<radio-toggle class="radio-group--inline"
											id="has_adverse_events"
											label="Évènements indésirables au cours du traitement ?"
											v-model="value.treatments.has_adverse_events"
											v-bind="$attrs"
											required
											@input="resetAdverseEventsExpected"></radio-toggle>

				<div class="rythm-v"
						 v-if="parseInt(value?.treatments?.has_adverse_events)">
					<p><strong>Évènements indésirables attendus :</strong></p>
					<div class="stripped-children">

						<div v-for="(treatment, index) in value.treatments.adverse_events_expected"
								 :key="`adverse_events_expected_${index}`"
								 class="flex-row flex-gap">

							<!-- Treatment selected -->
							<div class="flex-item--auto">
								<radio-toggle class="radio-group--inline"
															:id="`${treatment.slug}_selected`"
															:label="`${treatment.label} :`"
															v-model="treatment.value"
															@input="resetConditionalProps(treatment)"
															v-bind="$attrs"
															required></radio-toggle>
							</div>

							<!-- Sub fields -->
							<div class="flex-item--auto"
									 v-if="parseInt(treatment.value) && treatment?.subfields?.length">

								<div class="flex-col flex-align-right">

									<div class="flex-item"
											 v-for="subfield in treatment.subfields"
											 :key="subfield.slug">

										<radio-toggle class="radio-group--inline"
																	:id="`${subfield.slug}_selected`"
																	:label="`${subfield.label} :`"
																	v-model="subfield.value"></radio-toggle>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>


				<radio-toggle class="radio-group--inline"
											id="has_other_adverse_events"
											label="Autres évènements indésirables (non attendus) au cours du traitement ?"
											v-model="value.treatments.has_other_adverse_events"
											v-bind="$attrs"
											required></radio-toggle>


			</div>
		</section>

		<div class="rythm-v-large">

			<!-- S1+ Modification de traitement concomitant ? -->
			<section class="rythm-v-large"
							 v-if="parseInt(session.week) > 1">

				<hgroup class="rythm-v">
					<h4>Traitements concomitants</h4>
					<p v-if="parseInt(session.week) > 2"><button type="button"
										class="button--secondary"
										@click="loadPreviousSessionTreatmentsData(['has_concomitant_treatments_mods', 'concomitant_mods'])">
							<IconImport></IconImport>
							<span class="caption">Reprendre les données de la session précédente</span>
						</button></p>
				</hgroup>

				<radio-toggle class="radio-group--inline"
											id="has_concomitant_treatments_mods"
											label="Modification de traitement concomitant ?"
											v-model="value.treatments.has_concomitant_treatments_mods"
											v-bind="$attrs"
											required></radio-toggle>

				<!-- concomitant treatments modifications -->
				<table v-if="Object.values(value?.treatments?.concomitant_mods).length">
					<thead>
						<tr>
							<th>Traitement</th>
							<th>Posologie et voie d’administration</th>
							<th>Indication</th>
							<th>Date de début du traitement</th>
							<th>Date de fin du traitement</th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="(concomitantMod, index) in value.treatments.concomitant_mods"
								:key="`concomitant_mod_${index}`">
							<td>{{ concomitantMod.label }}</td>
							<td>{{ concomitantMod.dosage }}</td>
							<td>{{ concomitantMod.indication }}</td>
							<td>{{ concomitantMod.start }}</td>
							<td>{{ concomitantMod.stop }}</td>
							<td class="text-right">
								<button type="button"
												class="button--danger--outline"
												title="Supprimer"
												@click="removeConcomitantTreatmentMod(concomitantMod)">
									<IconBin class="icon" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>

				<!-- Add concomitant treatments modifications -->
				<div class="card rythm-v"
						 v-if="parseInt(value?.treatments?.has_concomitant_treatments_mods)">

					<div class="flex-row flex-gap flex-align-top">

						<div class="flex-item--grow">
							<div class="input-group">
								<label for="">Nom</label>
								<input type="text"
											 name="concomitant_mod_label"
											 id="concomitant_mod_label"
											 v-model="concomitantTreatmentMod.label"
											 required />
							</div>
						</div>

						<div class="flex-item--grow">
							<div class="input-group">
								<label for="concomitant_mod_dosage">Dosage</label>
								<textarea cols="30"
													rows="2"
													name="concomitant_mod_dosage"
													id="concomitant_mod_dosage"
													v-model="concomitantTreatmentMod.dosage" />
							</div>
						</div>

						<div class="flex-item--grow">
							<div class="input-group">
								<label for="">Indication</label>
								<textarea cols="30"
													rows="2"
													name="concomitant_mod_indication"
													id="concomitant_mod_indication"
													v-model="concomitantTreatmentMod.indication" />
							</div>
						</div>

					</div>

					<div class="flex-row flex-gap flex-align-bottom">

						<div class="flex-item--grow">
							<div class="input-group">
								<label for="">Début du traitement</label>
								<input id="concomitant_mod_start"
											 name="concomitant_mod_start"
											 type="date"
											 v-model="concomitantTreatmentMod.start" />
							</div>
						</div>

						<div class="flex-item--grow">
							<div class="input-group">
								<label for="">Arrêt du traitement</label>
								<input id="concomitant_mod_stop"
											 name="concomitant_mod_stop"
											 type="date"
											 v-model="concomitantTreatmentMod.stop" />
							</div>
						</div>

						<div class="flex-item">
							<div class="input-group--controls">
								<button class="button--secondary"
												type="button"
												@click="addConcomitantTreatmentMod()"
												:disabled="!concomitantTreatmentMod.label">
									<IconPlus class="icon" />
									<span class="caption">Ajouter</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- Manifestations extra-articulaires – POLYARTHRITE RHUMATOÏDE -->
			<section class="rythm-v-large"
							 data-desease="Polyarthrite Rhumatoïde"
							 v-if="disease_type === 'Polyarthrite Rhumatoïde'">
				<h4>Manifestations extra-articulaires (polyarthrite rhumatoïde)</h4>

				<div class="stripped-children">
					<div v-for="(item, index) in value.extra_articular_manifestations_polyarthrite"
							 :key="`extra_articular_manifestations_polyarthrite_${index}`"
							 class="flex-row flex-gap">

						<div class="flex-item--auto">
							<radio-toggle class="radio-group--inline"
														:id="`${item.slug}_selected`"
														:label="`${item.label} :`"
														v-model="item.value"
														v-bind="$attrs"
														required></radio-toggle>
						</div>
					</div>
				</div>
			</section>

			<!-- Manifestations extra-articulaires – SPONDYLARTHRITE -->
			<section class="rythm-v-large"
							 data-desease="Spondylarthrite"
							 v-if="disease_type === 'Spondylarthrite'">
				<h4>Manifestations extra-articulaires (Spondylarthrite)</h4>

				<div class="stripped-children">

					<div v-for="(item, index) in value.extra_articular_manifestations_spondylarthrite"
							 :key="`extra_articular_manifestations_spondylarthrite_${index}`"
							 class="flex-row flex-gap">

						<div class="flex-item--auto">
							<radio-toggle class="radio-group--inline"
														:id="`${item.slug}_selected`"
														:label="`${item.label} :`"
														v-model="item.value"
														v-bind="$attrs"
														required></radio-toggle>
						</div>

						<div class="flex-item--auto">

							<div class="flex-row flex-gap flex-align-right">
								<!-- Sub fields -->
								<template v-if="parseInt(item.value) && item?.subfields?.length">
									<div class="flex-item"
											 v-for="subfield in item.subfields"
											 :key="subfield.slug">

										<div class="input-group--inline">
											<label :for="subfield.slug"
														 class="text-nowrap">{{ subfield.label }} :</label>
											<input type="text"
														 :name="subfield.slug"
														 :id="subfield.slug"
														 v-model="subfield.value"
														 v-bind="$attrs" />
										</div>
									</div>
								</template>
							</div>

						</div>

					</div>
				</div>

			</section>

			<!-- Manifestations extra-articulaires – RHUMATISME PSORIASIQUE -->
			<section class="rythm-v-large"
							 data-desease="Rhumatisme Psoriasique"
							 v-if="disease_type === 'Rhumatisme Psoriasique'">

				<h4>Manifestations extra-articulaires (Rhumatisme Psoriasique)</h4>

				<div class="stripped-children">

					<div v-for="(item, index) in value.extra_articular_manifestations_psoriatic_rheumatism"
							 :key="`extra_articular_manifestations_psoriatic_rheumatism_${index}`"
							 class="flex-row flex-gap">

						<div class="flex-item--auto">
							<radio-toggle class="radio-group--inline"
														:id="`${item.slug}_selected`"
														:label="`${item.label} :`"
														v-model="item.value"
														v-bind="$attrs"
														required></radio-toggle>
						</div>

						<div class="flex-item--auto">

							<div class="flex-row flex-gap flex-align-right">
								<!-- Sub fields -->
								<template v-if="parseInt(item.value) && item?.subfields?.length">
									<div class="flex-item"
											 v-for="subfield in item.subfields"
											 :key="subfield.slug">

										<div class="input-group--inline">
											<label :for="subfield.slug"
														 class="text-nowrap">{{ subfield.label }} :</label>
											<input type="text"
														 :name="subfield.slug"
														 :id="subfield.slug"
														 v-model="subfield.value"
														 v-bind="$attrs" />
										</div>
									</div>
								</template>
							</div>

						</div>

					</div>
				</div>

			</section>

			<!-- DAS 28 (Polyarthrite Rhumatoïde) -->
			<section v-if="disease_type === 'Polyarthrite Rhumatoïde'">
				<ScoreDas28 :session="session"
										v-bind="$attrs"
										v-model="value.score_das_28" />
			</section>

			<!-- DAPSA (Rhumatisme Psoriasique) -->
			<section v-if="disease_type === 'Rhumatisme Psoriasique'">
				<ScoreDapsa :session="session"
										v-bind="$attrs"
										v-model="value.score_dapsa" />
			</section>

			<!-- EVA – Spondylarthrite / Polyarthrite Rhumatoïde (Inclus dans ScoreDapsa pour le Rhumatisme psoriasique) -->
			<section class="rythm-v-large"
							 v-if="disease_type === 'Spondylarthrite' || disease_type === 'Polyarthrite Rhumatoïde'">
				<h4 id="eva-index">EVA</h4>

				<div class="rythm-v">

					<div class="eva rythm-v-large">
						<div>La question à formuler au patient est : <em>En tenant compte de votre douleur et des conséquences de votre douleur et des conséquences de votre {{ disease_type.toLowerCase() }} sur votre vie quotidienne, conjugale, familiale et sociale, comment évalueriez-vous l’activité de votre {{ disease_type.toLowerCase() }} durant la semaine précédente sur cette échelle où <strong>0 représente une maladie inactive</strong> et <strong>10 une maladie très active</strong> ?</em></div>

						<!-- EVA Activité patient - toutes les maladies -->
						<RangeInput id="activity_patient"
												label="<strong>EVA activité patient</strong>"
												min="0"
												max="10"
												data-display-range="true"
												required
												v-bind="$attrs"
												v-model="value.eva.activity_patient" />

						<!-- EVA Activité patient - Spondylarthrite / Polyarthrite Rhumatoïde -->
						<RangeInput id="activity_doctor"
												label="<strong>EVA activité médecin</strong>"
												min="0"
												max="10"
												data-display-range="true"
												required
												v-bind="$attrs"
												v-model="value.eva.activity_doctor" />

						<!-- EVA Douleur patient - Spondylarthrite / Polyarthrite Rhumatoïde -->
						<div class="rythm-v-large">
							<div>
								Si le patient présente plusieurs sites douloureux ou des accès de douleur, chacun doit être évalué séparément. Il est possible de le faire rétrospectivement. Sur cette échelle, <strong>0 répresente « pas de douleur »</strong> et <strong>10 une « douleur maximale imaginable ».</strong>
							</div>

							<div class="label"><strong>EVA douleur patient :</strong></div>

							<table v-if="Object.entries(value.eva.pain_patient).length">
								<thead>
									<tr>
										<th>Site de la douleur</th>
										<th>Douleur</th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									<tr v-for="evaPain in value.eva.pain_patient"
											:key="evaPain.slug">
										<td>{{ evaPain.label }}</td>
										<td>{{ evaPain.value }}</td>
										<td class="text-right">
											<button type="button"
															class="button--danger--outline"
															title="Supprimer"
															@click="removeEvaPainSite(evaPain)">
												<IconBin class="icon" />
											</button>
										</td>
									</tr>
								</tbody>
							</table>

							<div class="card flex-row flex-gap flex-align-spaced flex-center-v">

								<div class="input-group--inline required">
									<label for="eva_site_pain">Site de la douleur :</label>
									<input type="text"
												 name="eva_site_pain"
												 id="eva_site_pain"
												 v-model="evaPain.label" />
								</div>

								<RangeInput class="range-input-inline"
														id="pain_patient"
														label="Douleur du site"
														min="0"
														max="10"
														data-display-range="true"
														v-model="evaPain.value" />

								<button class="button--secondary"
												type="button"
												@click="addEvaPainSite()"
												:disabled="!evaPain.label">
									<IconPlus class="icon" />
									<span class="caption">Ajouter</span>
								</button>

							</div>
						</div>

						<!-- EVA Asthénie - Polyarthrite Rhumatoïde -->
						<RangeInput id="asthenia_patient"
												v-if="disease_type === 'Polyarthrite Rhumatoïde'"
												label="<strong>EVA asthénie patient</strong>"
												before="Fatigue absente"
												middle="Fatigue modérée"
												after="Fatigue sévère"
												min="0"
												max="10"
												data-display-range="true"
												required
												v-bind="$attrs"
												v-model="value.eva.asthenia_patient" />
					</div>

				</div>

			</section>

			<!-- BASDAI -->
			<section v-if="disease_type === 'Spondylarthrite'">
				<ScoreBasdai v-model="value.score_basdai" />
			</section>

			<!-- BASFI -->
			<section v-if="disease_type === 'Spondylarthrite'">
				<ScoreBasfi v-model="value.score_basfi" />
			</section>

			<!-- Pourcentage d'efficacité -->
			<section class="rythm-v-large"
							 v-if="parseInt(session.week) > 1">
				<h3>Pourcentage d’efficacité</h3>

				<p class="required">Depuis le début de votre traitement, comment qualifieriez-vous le changement (s’il existe) sur la limitation de vos activités, vos symptômes, vos émotions et tout ce qui fait votre quotidien. <br>Cochez la phrase qui vous correspond.</p>

				<EfficiencyPercentage v-bind="$attrs"
															v-model="value.efficiency_percentage" />
			</section>
		</div>

	</section>
</template>


<script>
import { slugify } from "../libs/helpers";

import radioToggle from "@/components/radioToggle.vue";
import RangeInput from "@/components/RangeInput.vue";
import ScoreDas28 from "@/components/ScoreDas28.vue";
import ScoreDapsa from "@/components/ScoreDapsa.vue";
import ScoreBasdai from "@/components/ScoreBasdai.vue";
import ScoreBasfi from "@/components/ScoreBasfi.vue";
import EfficiencyPercentage from "@/components/EfficiencyPercentage.vue";

import IconPlus from "@/assets/svg/icons/icon-plus.svg";
import IconBin from "@/assets/svg/icons/icon-bin.svg";
import IconImport from "@/assets/svg/icons/icon-import.svg";

export default {
	name: 'ClinicalData',
	inheritAttrs: false,
	components: {
		RangeInput,
		IconPlus,
		IconBin,
		IconImport,
		radioToggle,
		ScoreDas28,
		ScoreDapsa,
		ScoreBasdai,
		ScoreBasfi,
		EfficiencyPercentage
	},
	props: [
		'value', // Current Session Clinical Data (caseData.session[sid].clinical_data)
		'session', // currentSession (read-only computed from caseData.session[sid])
		'previousSession',
		'disease_type'
	],

	data() {
		return {
			// Concomitant treatment modification model
			concomitantTreatmentMod: {
				label: "",
				dosage: "",
				indication: "",
				start: "",
				stop: ""
			},

			// Additionnal treatment model
			additionnalTreatment: {
				label: "",
				dosage: "",
			},

			// EVA pain model
			evaPain: {
				label: "",
				value: 0,
			},
		}
	},

	computed: {

		concomitantTreatmentModCounter() {
			const count = Object.values(this?.value?.treatments?.concomitant_mods).length;
			return count ? count : 0;
		},

		additionnalTreatmentCounter() {
			const count = Object.values(this?.value?.treatments?.additional).length;
			return count ? count : 0;
		},

		additionnalEvaPainSite() {
			const count = Object.values(this?.value?.eva?.pain_patient).length;
			return count ? count : 0;
		},
	},

	methods: {

		// Clone previous session treatments data and merge with current
		loadPreviousSessionTreatmentsData(keys) {
			keys.forEach(key => {
				const currentData = this.value?.treatments[key] || null;
				const previousData = this.previousSession?.clinical_data?.treatments[key] ? JSON.parse(JSON.stringify(this.previousSession?.clinical_data?.treatments[key])) : null;

				if (!previousData) {
					return;
				}

				const mergedData = (() => {
					if ((currentData && typeof currentData === 'string') || (previousData && typeof previousData === 'string')) return previousData || currentData;
					if ((currentData && Array.isArray(currentData)) || (previousData && Array.isArray(previousData))) return [...(currentData || []), ...(previousData || [])];
					if ((currentData && typeof currentData === 'object') || (previousData && typeof previousData === 'object')) return { ...currentData, ...previousData };
					return previousData || currentData;
				})();

				this.$set(this.value.treatments, key, mergedData);
			});
		},

		addEvaPainSite() {
			if (!this?.evaPain?.label) return;

			const counter = this.additionnalEvaPainSite + 1; // increment unique id
			const painSite = { ...this.evaPain };

			let data = {
				'label': painSite.label,
				'value': painSite.value,
				'slug': slugify(painSite.label, "_") + "_" + counter
			};

			// Add additional treatment
			const painSites = { ...this.value.eva.pain_patient, [data.slug]: { ...data } };
			this.$set(this.value.eva, 'pain_patient', painSites);

			// Reset the form
			this.evaPain = {
				label: "",
				value: 0,
			}

		},

		addConcomitantTreatmentMod() {
			// Push a new treatment additional treatment
			if (!this?.concomitantTreatmentMod?.label) return;

			const counter = this.concomitantTreatmentModCounter + 1; // increment unique id
			const concomitantTreatmentMod = { ...this.concomitantTreatmentMod };

			let t = {
				'label': concomitantTreatmentMod.label,
				'dosage': concomitantTreatmentMod.dosage,
				'slug': slugify(concomitantTreatmentMod.label, "_") + "_" + counter,
				'indication': concomitantTreatmentMod.indication,
				'start': concomitantTreatmentMod.start,
				'stop': concomitantTreatmentMod.stop
			};

			// Add concomitant treatment
			const concomitantTreatmentMods = { ...this.value.treatments.concomitant_mods, [t.slug]: { ...t } };
			this.$set(this.value.treatments, 'concomitant_mods', concomitantTreatmentMods);

			// Reset the form
			this.concomitantTreatmentMod = {
				label: "",
				dosage: "",
				indication: "",
				start: "",
				stop: ""
			}

		},

		addTreatment() {
			// Push a new treatment additional treatment
			if (!this?.additionnalTreatment?.label) return;

			const counter = this.additionnalTreatmentCounter + 1; // increment unique id
			const additionnalTreatment = { ...this.additionnalTreatment };

			let t = {
				'label': additionnalTreatment.label,
				'dosage': additionnalTreatment.dosage,
				'slug': slugify(additionnalTreatment.label, "_") + "_" + counter
			};

			// Add additional treatment
			const additionnalTreatments = { ...this.value.treatments.additional, [t.slug]: { ...t } };
			this.$set(this.value.treatments, 'additional', additionnalTreatments);

			// Reset the form
			this.additionnalTreatment = {
				label: "",
				dosage: "",
			}

		},

		removeEvaPainSite(item) {
			this.$delete(this.value.eva.pain_patient, item.slug);
		},

		removeTreatment(addt) {
			this.$delete(this.value.treatments.additional, addt.slug);
		},

		removeConcomitantTreatmentMod(addt) {
			this.$delete(this.value.treatments.concomitant_mods, addt.slug);
		},

		resetAdverseEventsExpected() {
			if (parseInt(this?.value?.treatments?.has_adverse_events)) return;

			Object.values(this.value.treatments.adverse_events_expected).forEach(v => {
				v.value = "";
				if (v?.subfields?.length) v.subfields.forEach(s => s.value = "");
			});
		},

		/**
		 * Reset object properties listed in "props" 
		 * if (int) object.selected === 0
		 *
		 * @param object data Object to reset the properties from 
		 * @param array	props Properties to reset
		 * @return void
		 */
		resetConditionalProps(data, props = []) {

			if (!parseInt(data?.selected)) {

				if (props?.length) {
					props.forEach(f => {
						data[f] = '';
					});
				}

				if (data?.subfields?.length) {
					data.subfields.forEach(s => s.value = '');
				}

			}
		},

	},

}
</script>


<style lang="scss" scoped>
::v-deep {

	.input-slider:not(.range-input-inline) {
		flex-flow: row wrap;
		justify-content: space-between;

		.range-slider {
			flex-basis: 30rem;
		}
	}

	.range-input-inline {
		display: flex;
		flex-flow: row wrap;

		.range-slider {
			flex-basis: auto;
		}
	}

}
</style>